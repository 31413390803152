
export const environment = {
   production: false,
   timer: 10000,
   ordersDeletePED: 60,
   ordersDeleteOthers: 1440,
   apiRider: "https://api-sgpzjvoz3a-ew.a.run.app/v1/",
   forbiddenUrl: "./assets/html/forbidden/index.html",
   firebase: {
      apiKey: "AIzaSyCcEj6SRBAHGgS3gkL805FS_9elach2Db0",
      authDomain: "ridersapp-8f080.firebaseapp.com",
      projectId: "ridersapp",
      storageBucket: "ridersapp.appspot.com",
      messagingSenderId: "832252525573",
      appId: "1:832252525573:web:1652af71c7299d2039706c",
      vapidKey: "BGAulxNyDNADpAtgb4WW-xbpxkzTBHw6VFq7Hi_bqf20XLcV63ec5UZLXlyV715t8MRjPr7M4JCesvJPxA1CdjU"
    },
    mixpanelToken: "930497fc767c6f9341bab363fb286f18"
};
