import { Pipe, PipeTransform } from '@angular/core';
import { OrderItemModel } from 'src/app/models';

@Pipe({
  name: 'duplicatedMessage'
})
export class DuplicatedMessagePipe implements PipeTransform {

  transform(order: OrderItemModel): string {
    return `${order.delivery_channel.name} - ${order.delivery_channel_order_id}`;
  }
}
