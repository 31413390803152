import { Pipe, PipeTransform } from '@angular/core';
import { StatusType } from '../../models';

StatusType

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: string): string {
    let statusText: string;
    switch (value.toString()) {
      case "ACC":
        statusText = StatusType.ACC;
        break;
      case "STC":
        statusText = StatusType.STC;
        break;
      case "PICK":
        statusText = StatusType.PICK;
        break;
      case "PED":
        statusText = StatusType.PED;
        break;
      case "ERR":
        statusText = StatusType.ERR;
        break;
      case "REJ":
        statusText = StatusType.REJ;
        break;
      case "CANR":
        statusText = StatusType.CANR;
        break;
      case "CAN":
        statusText = StatusType.CANR;
        break;
    }
    return statusText;
  }

}
