export class OrderItemModel {
    id: string;
    created_at: Date;
    delivery_channel: DeliveryChannelModel;
    delivery_channel_order_id: string;
    restaurant_order_id: string;
    paid_amount: string;
    pickup_at: Date;
    restaurant: RestaurantModel;
    short_order_id: string;
    updated_at: Date;
    customer_name: string;
    status: string;
    ultrashort_order_id: string;
    ultrashort_orders: string[];
    timestamp_ACC?: string;
    timestamp_PED?: string;
    timestamp_PICK?: string;
    timestamp_STC?: string;
    storage_time: Date;

    constructor(
        id: string,
        created_at: Date,
        delivery_channel: DeliveryChannelModel,
        delivery_channel_order_id: string,
        restaurant_order_id: string,
        paid_amount: string,
        pickup_at: Date,
        restaurant: RestaurantModel,
        short_order_id: string,
        updated_at: Date,
        customer_name: string,
        status: string,
        ultrashort_order_id: string,
        ultrashort_orders: string[],
        timestamp_ACC?: string,
        timestamp_PED?: string,
        timestamp_PICK?: string,
        timestamp_STC?: string,
        storage_time?: Date) {
            this.id = id;
            this.created_at = created_at;
            this.delivery_channel = new DeliveryChannelModel(delivery_channel.id, delivery_channel.name, delivery_channel.logo);
            this.delivery_channel_order_id = delivery_channel_order_id;
            this.restaurant_order_id = restaurant_order_id;
            this.paid_amount = paid_amount;
            this.pickup_at = pickup_at;
            this.restaurant = new RestaurantModel(restaurant.id, restaurant.name);
            this.short_order_id = short_order_id;
            this.updated_at = updated_at;
            this.customer_name = customer_name;
            this.status = status;
            this.ultrashort_order_id = ultrashort_order_id;
            this.ultrashort_orders = ultrashort_orders;
            this.timestamp_ACC = timestamp_ACC;
            this.timestamp_PED = timestamp_PED;
            this.timestamp_PICK = timestamp_PICK;
            this.timestamp_STC = timestamp_STC;
            this.storage_time = storage_time;
    }
}

export class DeliveryChannelModel {
    id: string;
    name: string;
    logo: string;
    constructor(id: string, name: string, logo: string) {
        this.id = id;
        this.name = name;
        this.logo = logo;
    }
}

export class RestaurantModel {
    id: string;
    name: string;
    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}