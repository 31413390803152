import { OrderItemModel } from "./order.model";

export class CheckOrders {
    dataCodes: Dictionary<OrderItemModel[]> = {};
    constructor(codes: string[]) {
        codes.map(code => {
            this.dataCodes[code] = [];
        })
    }
}

interface Dictionary<T> {
    [Key: string]: T;
}
