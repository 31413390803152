import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MixpanelService } from '../services/mixpanel.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    loaderToShow: any;
    constructor(
        public loadingController: LoadingController,
        private router: Router,
        private mixpanelService: MixpanelService
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');

        // Authentication by setting header with token value
        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });
        // this.showLoader();
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                // this.hideLoader();
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.error(error);
                this.errorCustomController(error);
                // this.hideLoader();
                return throwError(error);
            }));
    }

    errorCustomController(error: HttpErrorResponse){
        switch (error.status) {
            case 401:
            case 403:
            case 500:
                this.mixpanelService.event('API error', `{url: ${error.url}, status: ${error.status}}`)
                localStorage.removeItem('token');
                localStorage.removeItem('orders');
                window.location.href = environment.forbiddenUrl;
                break;
        }

    }

    // showLoader() {
    //     this.loaderToShow = this.loadingController.create({
    //         message: 'Processing Server Request'
    //     }).then((res) => {
    //         res.present();

    //         res.onDidDismiss().then((dis) => {
    //             console.log('Loading dismissed!');
    //         });
    //     });
    //     this.hideLoader();
    // }

    // hideLoader() {
    //     this.loadingController.dismiss();
    // }


}