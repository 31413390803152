import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrderItemModel } from '../models';
import { RidersService } from './riders.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  currentIds: string[] = [];
  currentOrders: OrderItemModel[] = [];
  currentOrders$: BehaviorSubject<OrderItemModel[]> = new BehaviorSubject([]);

  constructor(
    private ridersService: RidersService,
    private navCtrl: NavController,
    ) {
    this.currentIds = (JSON.parse(localStorage.getItem('ordersId')) || []);
  }

  checkOrders(){
    if(this.currentIds.length > 0){
      let suscription = this.ridersService.getOrders(this.currentIds.join(',')).pipe(
        map((orders:OrderItemModel[])=>{
          const filteredOrders: OrderItemModel[] = orders.filter((order: OrderItemModel) => this.checkDateOrder(order));
          return filteredOrders;
        })
      ).subscribe((orders:OrderItemModel[]) =>{
        this.updateIds(orders);
        this.currentOrders$.next(orders);
        suscription.unsubscribe()
        if(orders.length > 0) {
          this.navCtrl.navigateForward('/orders');
        }else{
          this.navCtrl.navigateForward('/home');
        };
      })
    }else{
      this.navCtrl.navigateForward('/home');
    };
  }

  checkDateOrder(order: OrderItemModel){
    const now = moment(new Date());
    const dateToCheck = (order.status == 'PED') ? order.timestamp_PED : order.timestamp_ACC;
    const timeLimit: number = (order.status == 'PED') ? environment.ordersDeletePED : environment.ordersDeleteOthers;
    const orderDate = moment(dateToCheck); // orderDate date
    const difference = moment.duration(now.diff(orderDate));
    const minutes = difference.asMinutes();

    return minutes <= timeLimit;
  }

  updateIds(orders: OrderItemModel[]){
    this.currentIds = [];
    orders.map(order => {this.currentIds.push(order.id)});
    localStorage.setItem('ordersId', JSON.stringify(this.currentIds));
    if(this.currentIds.length === 0){
      this.navCtrl.navigateForward('/home');
    }
  }

  updateOrders(updateOrders: OrderItemModel[]){
    let newOrdersArr: OrderItemModel[] = [];
    let settedOrders: OrderItemModel[] = [];
    if(this.currentOrders.length > 0){
      settedOrders = this.currentOrders.filter(currentOrder => {
        return !updateOrders.some(updateOrder => {
          return updateOrder.id === currentOrder.id;
        });
      });
      newOrdersArr = [...updateOrders].concat([...settedOrders]);
    }else{
      newOrdersArr = updateOrders;
    }
    this.resetStorage(newOrdersArr);
  }

  resetStorage(newOrdersArr: OrderItemModel[]){
    const filteredOrders: OrderItemModel[] = newOrdersArr.filter((order: OrderItemModel) => this.checkDateOrder(order));
    this.currentOrders = filteredOrders;
    this.updateIds(this.currentOrders);
    this.currentOrders$.next(this.currentOrders);
  }

  deleteOrder(orderToDelete: OrderItemModel){
    let newOrdersArr: OrderItemModel[] = [...this.currentOrders].filter(order => order.id !== orderToDelete.id);
    this.resetStorage(newOrdersArr);
  }
}
