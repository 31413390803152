import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CheckOrders, DeviceModel, OrderItemModel } from '../models';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class RidersService {

  constructor(private http: HttpClient) { }

  checkQR(): Observable<any> {
    const url: string = `${environment.apiRider}qr`;

    return this.http.get(url)
      .pipe(
        tap(_ => this.log('response received'))
      );
  }

  getOrders(ordersId: string): Observable<OrderItemModel[]> {
    const url: string = `${environment.apiRider}orders/${ordersId}`
    return this.http.get(url)
      .pipe(
        map((data:OrderItemModel[]) =>{
          return data;
        }),
        tap(_ => this.log('response received')),
        catchError(this.handleError('getOrders', []))
      );
  }

  checkOrder(codesUser: string): Observable<any[]| CheckOrders> {
    const url: string = `${environment.apiRider}orders?usOrderIds=${codesUser}`
    return this.http.get(url)
      .pipe(
        map((data:OrderItemModel[]) =>{
          const codes = codesUser.split(",");
          let ordersResponse: CheckOrders = new CheckOrders(codes);
          if (data.length <= 0) {
            ordersResponse.dataCodes = {};
          } else {
            data.map((order: OrderItemModel) =>{
              ordersResponse = this.setDataOrders(ordersResponse, order, codes);
            });
          }
          return ordersResponse;
        }),
        tap(_ => this.log('response received')),
      );
  }

  setDataOrders(ordersResponse: CheckOrders, order: OrderItemModel, codes: string[]):CheckOrders {

    if(order.ultrashort_orders.length == 1){
      ordersResponse.dataCodes[order.ultrashort_orders[0]].push(order);
    }else {
      const codesSetted: string[] = order.ultrashort_orders.filter(element => codes.includes(element));
      if(codesSetted){
        codesSetted.map((codeSetted: string) =>{
          ordersResponse.dataCodes[codeSetted].push(order);
        })
      }
    }
    return ordersResponse
  }

  deviceData(device: DeviceModel, oderId:string): Observable<any> {
    const url: string = `${environment.apiRider}orders/${oderId}`
    return this.http.post(url, device)
      .pipe(
        tap(_ => this.log('response received')),
        catchError(this.handleError('deviceData', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      if(operation = 'getOrders'){
        return of(result);
      }else{
        return of(error);
      }
    };
  }

  private log(message: string) {
    // console.log(message);
  }
}
