import { NgModule } from '@angular/core';
import { OrderStatusPipe } from './order-status/order-status.pipe';
import { DuplicatedMessagePipe } from './duplicated-message/duplicated-message.pipe';


@NgModule({
  declarations: [
    OrderStatusPipe,
    DuplicatedMessagePipe
  ],
  imports: [],
  exports: [
    OrderStatusPipe,
    DuplicatedMessagePipe
  ]
})
export class PipesModule { }
