import { Injectable } from '@angular/core';
import { NgxMixpanelService } from 'ngx-mixpanel';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor(private mixPanelService: NgxMixpanelService) {
    this.init();
  }

  init(){
    this.mixPanelService.config(environment.mixpanelToken);
  }

  login(id: string){
    this.mixPanelService.login(id);
  }

  logout(){
    this.mixPanelService.logout();
  }

  event(eventName: string, path: string, details?: any){
    this.mixPanelService.eventRegister(eventName, path, details);
  }
}
