import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './services/mixpanel.service';
import { RidersService } from './services/riders.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private ridersService: RidersService,
    private storageService: StorageService,
    private mixpanelService: MixpanelService) {
    translate.addLangs(['es', 'en']);
    // translate.setDefaultLang('en');
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  ngOnInit(): void {
    this.setLanguage()
    
  }

  private setLanguage() {
    const userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    switch (true) {
      case userLocale.toLowerCase().includes("en"):
        this.switchLang("en");
        break;
    
      default:
        this.switchLang("es");
        break;
    }
    this.checkToken();
  }

  private checkToken() {
    const params = new URLSearchParams(window.location.search);
    let orderId: string;
    for (const [key, value] of params.entries()) {
      const checkVal = (key == 'token') ? decodeURI(value) : value;

      if (key == 'token') localStorage.setItem(key, checkVal.toString());
      if (key == 'orderId') {
        let mixpanelProperties = {'orderId': checkVal};
        this.mixpanelService.event('access by link', 'init app', mixpanelProperties);
        orderId = checkVal; 
      };
    }

    if (!!localStorage.getItem('token')) {
      this.ridersService.checkQR().subscribe(
        res => {
          localStorage.setItem('site_data', JSON.stringify(res));
          this.storageService.checkOrders();
        },
        err => {
          this.setToken();
        })
    } else {
      this.setToken();
    }
  }

  private setToken() {
    localStorage.removeItem('token');
    window.location.href = environment.forbiddenUrl;
  }
}